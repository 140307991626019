<template>
  <LiefengContent>
    <template v-slot:title>领取记录</template>
    <template v-slot:toolsbarRight>
      <div class="option" ref="option">
        <Icon type="ios-arrow-back" v-if="showIcon" @click="handleClickLeft" class="left-icon" />
        <ul
          class="optionList"
          ref="optionList"
          :style="{padding: showIcon ? '0 14px' : '0px',left: '0px'}"
        >
          <li v-for="(item, index) in optionList" :key="index">
            选项{{ item.itemNum }}:
            <span>{{ item.vateTotal }}件</span>
          </li>
        </ul>
        <Icon
          type="ios-arrow-forward"
          @click="handleClickRight"
          v-if="showIcon"
          class="right-icon"
        />
      </div>
      <Button type="primary" @click="chartSet" style="margin-right:10px">领取统计分析</Button>
      <Button @click="exportExcel" type="error" icon="ios-cloud-download-outline">导出领取记录</Button>
      <Button @click="$router.push('/voteindex?menuId=' + $route.query.menuId)" type="success">返回</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="currentPage"
        @hadlePageSize="pageChange"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :pageSize="pageSize"
      ></LiefengTable>
      <LiefengModal
        title="领取统计分析"
        :value="addModel.status"
        @input="addGridStatusFn"
        :fullscreen="true"
      >
        <template v-slot:contentarea>
          <ChartTable :businessCode="pushBusinessCode"></ChartTable>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/voterecord")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import ChartTable from "./childrens/charttable";
import qs from "qs";
export default {
  data() {
    return {
      loading: false,
      //选项列表
      optionList: [],
      showIcon: false,
      //表头列表
      talbeColumns: [
        {
          title: "序号",
          type: "index",
          width: 110,
          align: "center"
        },
        {
          title: "姓名",
          key: "userName",
          width: 130,
          align: "center"
        },
        {
          title: "年龄",
          key: "age",
          width: 100,
          align: "center"
        },
        {
          title: "性别",
          key: "sex",
          width: 100,
          align: "center"
        },
        {
          title: "身份类型",
          key: "idNumType",
          width: 150,
          align: "center"
        },
        {
          title: "社区名称",
          key: "communityName",
          align: "center"
        },
        {
          title: "街道名称",
          key: "streetName",
          align: "center"
        },
        {
          title: "手机号码",
          key: "mobile",
          width: 160,
          align: "center"
        },
        {
          title: "领取结果",
          key: "voteItemNum",
          width: 120,
          align: "center"
        },
        {
          title: "操作时间",
          key: "voteTime",
          align: "center"
        }
      ],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,

      // zhw添加
      addModel: {
        status: false,
        tip: false
      },
      pushBusinessCode: ""
    };
  },
  methods: {
    // zhw添加的代码
    chartSet() {
      this.addModel.status = true;
      this.pushBusinessCode = "";
      this.pushBusinessCode = this.$route.query.id;
    },
    addGridStatusFn(status) {
      this.addModel.status = status;
    },
    // zhw结束
    handleClickLeft() {
      let optionWidth = this.$refs.option.offsetWidth;
      let left = parseInt(this.$refs.optionList.style.left);
      if (left + optionWidth >= 0) {
        this.$refs.optionList.style.left = "0px";
      } else {
        this.$refs.optionList.style.left = left + optionWidth + "px";
      }
    },
    handleClickRight() {
      let optionWidth = this.$refs.option.offsetWidth;
      let left = parseInt(this.$refs.optionList.style.left);
      if (-left + optionWidth >= this.$refs.optionList.offsetWidth) {
        this.$refs.optionList.style.left =
          -this.$refs.optionList.offsetWidth + optionWidth + "px";
      } else {
        this.$refs.optionList.style.left = left - optionWidth + "px";
      }
    },
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？</p>",
            onOk: () => {
              this.$get('/voteapi/api/pc/componentVote/exportVoteRecord', {
                businessType: "2",
                businessCode: this.$route.query.id,
              }).then(res => {
                if (res.code == 200) {
                  if (res.data) {
                    if (res.data.slice(0, 4) == 'http' && res.data.slice(0, 5) !== 'https') {
                      res.data = res.data.replace('http', 'https')
                    }
                  }
                  let link = document.createElement("a");
                  link.href = res.data
                  document.body.appendChild(link);
                  link.click();
                  link.download = '导出全部信息'
                  document.body.removeChild(link);
                  this.$Message.destroy();
                } else {
                  this.$Message.error({
                    content: '导出失败',
                    background: true
                  });
                }
              })
              return
             
          // let tHeader = [
          //   "姓名",
          //   "年龄",
          //   "性别",
          //   "身份类型",
          //   "社区名称",
          //   "街道名称",
          //   "手机号码",
          //   "领取结果",
          //   "操作时间"
          // ];
          // let filterVal = [
          //   "userName",
          //   "age",
          //   "sex",
          //   "idNumType",
          //   "communityName",
          //   "streetName",
          //   "mobile",
          //   "voteItemNum",
          //   "voteTime"
          // ];
          // this.$core.exportExcel(
          //   tHeader,
          //   filterVal,
          //   this.tableData,
          //   "领取记录列表"
          // );
        }
      });
    },
    //修改res数据的时间格式
    dataFilter(res) {
      res.dataList.map((item, index, arr) => {
        arr[index].idNumType =
          item.idNumType == "0"
            ? "户籍人员"
            : item.idNumType == "1"
            ? "外来人员"
            : "其他";
        arr[index].sex = item.sex == "1" ? "男" : item.sex == "2" ? "女" : "";

        let startTime = new Date(item["voteTime"]);
        let startYear = startTime.getFullYear();
        let startMonth =
          startTime.getMonth() + 1 < 10
            ? "0" + (startTime.getMonth() + 1)
            : startTime.getMonth() + 1;
        let startDay =
          startTime.getDate() < 10
            ? "0" + startTime.getDate()
            : startTime.getDate();
        let startHorus =
          startTime.getHours() < 10
            ? "0" + startTime.getHours()
            : startTime.getHours();
        let startMinutes =
          startTime.getMinutes() < 10
            ? "0" + startTime.getMinutes()
            : startTime.getMinutes();
        let startSeconds =
          startTime.getSeconds() < 10
            ? "0" + startTime.getSeconds()
            : startTime.getSeconds();
        arr[index].voteTime =
          startYear +
          "-" +
          startMonth +
          "-" +
          startDay +
          " " +
          startHorus +
          ":" +
          startMinutes +
          ":" +
          startSeconds;
      });
      this.tableData = res.dataList;
    },
    pageChange(obj) {
      this.loading = true;
      this.$get("/voteapi/api/pc/componentVote/getVoteRecordPageByBusiness", {
        businessType: "2",
        businessCode: this.$route.query.id,
        page: obj.page,
        pageSize: obj.pageSize
      })
        .then(res => {
          if (res.code == 200) {
            this.dataFilter(res, "voteTime");
            this.currentPage = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
            this.loading = false;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
            this.loading = false;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.pageChange({
      page: 1,
      pageSize: this.pageSize
    });
    this.$get("/voteapi/api/pc/componentVote/getVoteItemListByBusiness", {
      businessType: "2",
      businessCode: this.$route.query.id
    }).then(res => {
      if (res.code == 200) {
        this.optionList = res.dataList;

        this.$nextTick(() => {
          let optionWidth = this.$refs.option.offsetWidth;
          if (150 * this.optionList.length > optionWidth) {
            this.showIcon = true;
            this.$refs.optionList.style.width =
              150 * this.optionList.length + "px";
          }
        });
      }
    });
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
    ChartTable
  }
};
</script>
    
<style scoped lang='less'>
.option {
  width: calc(100vw - 560px);
  overflow: hidden;
  height: 32px;
  line-height: 32px;
  position: relative;
  .left-icon {
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 32px;
    line-height: 32px;
    background: #fafafa;
  }
  .right-icon {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 32px;
    line-height: 32px;
    background: #fafafa;
  }
  .optionList {
    position: relative;
    transition: left 0.5s linear;
    li {
      display: inline-block;
      width: 120px;
      list-style: none;
      margin-left: 30px;
      font-size: 20px;
      span {
        margin-left: 10px;
      }
    }
  }
}
</style>